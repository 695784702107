import * as thumborUrl from "thumbor-url";
import axios from "axios";

type ImageRotation = 0 | 90 | 180 | 270;

interface Dimensions {
    width: number;
    height: number;
}

interface PixelCrop {
    x: number;
    y: number;
    width: number;
    height: number;
    type: "pixel";
}

interface PercentCrop {
    x: number;
    y: number;
    width: number;
    height: number;
    type: "percent";
}

export type Crop = PixelCrop | PercentCrop;

type Fit = Partial<Dimensions>;

interface ResizerOpts {
    crop: Crop | null;
    rotation?: ImageRotation;
    dimensions?: Dimensions | null;
}

interface RemoteImage {
    url: string;
    dimensions?: Dimensions;
}

export interface ThumborImageInterface {
    // Return instance with crop set.  Transforms crop argument based on current
    // rotation.
    withCrop(crop: Crop): ThumborImage;
    withoutCrop(): ThumborImage;

    getPercentCrop(): Promise<PercentCrop | null>;

    // Convenience methods to rotate by +/- 90 degrees.
    rotateCw(): ThumborImage;
    rotateCcw(): ThumborImage;

    // both of these will need to be async
    toUrl(fit?: Fit): Promise<URL>;
    toString(fit?: Fit): Promise<string>;
}

class ThumborImage implements ThumborImageInterface {
    private crop: Crop | null = null;
    private dimensions: Dimensions | null = null;
    private rotation: ImageRotation = 0 as ImageRotation;

    private getOpts = (): ResizerOpts => ({
        crop: this.crop,
        rotation: this.rotation,
        dimensions: this.dimensions
    });

    private constructor(private thumborBaseUrl: string, private imageSrc: string, opts: ResizerOpts) {
        this.crop = opts.crop || null;
        this.rotation = opts.rotation || 0;
        this.dimensions = opts.dimensions || null;
    }

    /**
     * @param url URL to extract image from in either Thumbor URL or valid S3 object URL
     * @return ThumborImage of URL
     */
    static fromUrl(url: string | URL): ThumborImage {
        if (typeof url === "string") {
            /**
             * Throw if invalid url?
             */
            url = new URL(url);
        }
        const parsed = thumborUrl.parse(url.pathname);
        const { crop, image, filters } = parsed;
        const { bottom, left, right, top } = crop;
        let rotation: ImageRotation = 0;
        let newCrop: Crop | null = null;
        const dimensions: Dimensions | null = null;
        const imageSrc = image;
        const thumborBaseUrl = process.env.REACT_APP_THUMBOR_BASEURL || "https://resizer.simplyframed.com";

        if (filters) {
            const [
                {
                    args: [rotateString]
                }
            ] = filters;
            rotation = parseInt(rotateString) as ImageRotation;
        }
        if ([bottom, left, right, top].every(val => !isNaN(parseInt(String(val))))) {
            newCrop = {
                x: left,
                y: top,
                width: right - left,
                height: bottom - top,
                type: "pixel"
            };
        }

        const opts = {
            crop: newCrop,
            rotation,
            dimensions
        };
        return new ThumborImage(thumborBaseUrl, imageSrc, opts);
    }

    rotateCw(): ThumborImage {
        let { dimensions, rotation } = this;
        if (rotation > 0) {
            rotation = (rotation - 90) as ImageRotation;
        } else {
            rotation = 270;
        }

        if (dimensions) {
            dimensions = { width: dimensions.height, height: dimensions.width };
        }

        return new ThumborImage(this.thumborBaseUrl, this.imageSrc, { ...this.getOpts(), rotation, dimensions });
    }

    rotateCcw(): ThumborImage {
        let { rotation, dimensions } = this;

        if (rotation < 270) {
            rotation = (rotation + 90) as ImageRotation;
        } else {
            rotation = 0;
        }

        if (dimensions) {
            dimensions = { width: dimensions.height, height: dimensions.width };
        }

        return new ThumborImage(this.thumborBaseUrl, this.imageSrc, {
            ...this.getOpts(),
            dimensions,
            rotation
        });
    }

    withCrop(crop: Crop): ThumborImage {
        return new ThumborImage(this.thumborBaseUrl, this.imageSrc, {
            ...this.getOpts(),
            crop
        });
    }

    withoutCrop(): ThumborImage {
        return new ThumborImage(this.thumborBaseUrl, this.imageSrc, {
            ...this.getOpts(),
            crop: null
        });
    }

    /**
     * Return crop adjusted to current rotation
     * @param crop Crop to readjust
     * @param dimensions Actual W x H of image (or 100 x 100 if in percent)
     */
    private adjustCrop(crop: Crop, dimensions: Dimensions): Crop {
        let newCrop;
        const ogX = crop.x;
        const ogY = crop.y;
        const ogW = crop.width;
        const ogH = crop.height;
        const { width, height } = dimensions;
        switch (this.rotation) {
            case 90:
                newCrop = {
                    x: ogY,
                    y: width - (ogX + ogW),
                    width: ogH,
                    height: ogW,
                    type: crop.type
                };
                break;
            case 180:
                newCrop = {
                    x: width - (ogX + ogW),
                    y: height - (ogY + ogH),
                    width: ogW,
                    height: ogH,
                    type: crop.type
                };
                break;
            case 270:
                newCrop = {
                    x: height - (ogY + ogH),
                    y: ogX,
                    width: ogH,
                    height: ogW,
                    type: crop.type
                };
                break;
            default:
                newCrop = {
                    x: ogX,
                    y: ogY,
                    width: ogW,
                    height: ogH,
                    type: crop.type
                };
                break;
        }

        return newCrop as Crop;
    }

    /**
     * Get current set Crop in percent
     */
    async getPercentCrop(): Promise<PercentCrop | null> {
        if (!this.crop) {
            return null;
        }

        if (this.crop.type === "percent") {
            return this.crop;
        }
        let dimensions = await this.getDimensions();
        if (this.rotation === 270 || this.rotation === 90) {
            dimensions = {
                height: dimensions.width,
                width: dimensions.height
            };
        }
        const { crop } = this;
        const ogX = (crop.x / dimensions.width) * 100;
        const ogY = (crop.y / dimensions.height) * 100;
        const ogW = (crop.width / dimensions.width) * 100;
        const ogH = (crop.height / dimensions.height) * 100;
        let defaultCrop: PercentCrop = {
            x: ogX,
            y: ogY,
            height: ogH,
            width: ogW,
            type: "percent"
        };
        defaultCrop = this.adjustCrop(defaultCrop, { width: 100, height: 100 }) as PercentCrop;

        return defaultCrop;
    }

    /**
     * Get the current set Crop in pixels.
     */
    private async getPixelCrop(): Promise<PixelCrop | null> {
        if (!this.crop) {
            return null;
        }

        const dimensions = await this.getDimensions();
        // if (this.crop.type === "pixel") {
        //     return this.adjustCrop(this.crop, dimensions) as PixelCrop;
        // }

        function clamp(num: number, min: number, max: number) {
            return Math.min(Math.max(num, min), max);
        }

        const percentCrop = await this.getPercentCrop();

        // this can probably be removed, because of !this.crop above, right?
        if (!percentCrop) return null;

        const { height: naturalHeight, width: naturalWidth } = dimensions;

        const x = Math.round(naturalWidth * (percentCrop.x / 100));
        const y = Math.round(naturalHeight * (percentCrop.y / 100));
        const width = Math.round(naturalWidth * (percentCrop.width / 100));
        const height = Math.round(naturalHeight * (percentCrop.height / 100));

        return {
            x,
            y,
            // Clamp width and height so rounding doesn't cause the crop to exceed bounds.
            width: clamp(width, 0, naturalWidth - x),
            height: clamp(height, 0, naturalHeight - y),
            type: "pixel"
        };
    }

    private async loadImage(src: string): Promise<HTMLImageElement> {
        return new Promise((resolve: (img: HTMLImageElement) => void, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject;
            img.src = src;
        }).catch(e => {
            throw new Error(e);
        });
    }

    private async getDimensions() {
        if (!this.dimensions) {
            // const metaUrl = [this.thumborBaseUrl, "meta", this.imageSrc].join("/");
            // const {
            //     data: {
            //         thumbor: { source }
            //     }
            // } = await axios.get(metaUrl);
            const img = await this.loadImage([this.thumborBaseUrl, this.imageSrc].join("/"));
            const source = { width: img.naturalWidth, height: img.naturalHeight };
            if (this.rotation === 90 || this.rotation === 270) {
                this.dimensions = {
                    height: source.width,
                    width: source.height
                };
            } else {
                this.dimensions = {
                    height: source.height,
                    width: source.width
                };
            }
        }
        return this.dimensions;
    }

    private get rotationString() {
        if (this.rotation) {
            return `filters:rotate(${this.rotation})`;
        } else {
            return null;
        }
    }

    private getFitString(fit: Fit | null) {
        if (fit && fit.width && fit.height) {
            return `${fit.width}x${fit.height}`;
        } else {
            return null;
        }
    }

    /**
     * Convert type Crop to Thumbor readable coordinates
     */
    private async getCropString(): Promise<string | null> {
        const crop = await this.getPixelCrop();
        if (crop) {
            const { height: dHeight, width: dWidth } = await this.getDimensions();
            const { x, y, width, height } = crop;
            const topLeft = { x, y };
            const bottomRight = { x: x + width, y: y + height };
            let { x: tlX, y: tlY } = topLeft;
            let { x: brX, y: brY } = bottomRight;
            switch (this.rotation) {
                case 90:
                    tlX = dHeight - bottomRight.y;
                    brX = dHeight - topLeft.y;
                    tlY = topLeft.x;
                    brY = bottomRight.x;
                    break;
                case 180:
                    tlX = dWidth - bottomRight.x;
                    brX = dWidth - topLeft.x;
                    tlY = dHeight - bottomRight.y;
                    brY = dHeight - topLeft.y;
                    break;
                case 270:
                    tlX = topLeft.y;
                    brX = bottomRight.y;
                    tlY = dWidth - bottomRight.x;
                    brY = dWidth - topLeft.x;
                    break;
                default:
                    break;
            }
            return `${tlX}x${tlY}:${brX}x${brY}`;
        } else {
            return null;
        }
    }

    async toUrl(fit?: Fit): Promise<URL> {
        return new URL(await this.toString(fit));
    }

    async toString(fit?: Fit): Promise<string> {
        let fitString;
        if (fit !== undefined) {
            fitString = this.getFitString(fit);
        }
        return [
            this.thumborBaseUrl,
            await this.getCropString(),
            fit ? "fit-in" : null,
            fitString,
            this.rotationString,
            this.imageSrc
        ]
            .filter(Boolean)
            .join("/");
    }
}

export default ThumborImage;
